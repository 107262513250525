html,
body,
#root {
  height: 100%;

  @media #{$media-mobile} {
    font-size: 1rem;
  }
}

.capitalize {
  text-transform: capitalize;
}

.promo-label {
  display: inline-flex;
  background: var(--promoBgColor);
  color: var(--promoTextColor);
  right: -10px;
  top: 4px;
  z-index: 1;
  margin-left: auto;
  padding: 0.2rem 0.6rem;
  border-radius: 10px;
  font-size: 12px;

  &.credit-variable-top {
    top: -32px;
  }

  &.credit-variable {
    top: 80%;
    left: 50%;
    max-width: 130px;
    transform: translate(calc(-50% - 22px), -50%);
    text-align: center;
  }
}

.license-plate-label {
  color: var(--badgeLicensePlateTextColor);
  border: 2px solid var(--badgeLicensePlateBorderColor);
  background-color: var(--badgeLicensePlateBgColor);
  border-radius: 4px;
  font-weight: 800;
  padding: 0.1rem 0.5rem;
  width: 130px;
  height: 28px;
  text-align: center;
  margin-right: 2rem;
}

.license-plate-label-refused {
  @extend .license-plate-label;
  background: var(--badgeLicenseRefusedBgColor);
}

.refused-badge {
  padding: 6px 10px;
  border-radius: 10rem;
  color: var(--badgeLicensePlateTextColor);
  background: var(--badgeLicenseRefusedBgColor);
}

.info-label {
  position: absolute;
  top: 9px;
  right: 10px;
  border-radius: 50%;
  background: $blue;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
}

.badge-secondary {
  background: #67757c;
}

.badge {
  border-radius: 7.5px;
}

.btn {
  border-radius: 10px;
}

/**
Table Headers
*/
.rt-table {
  .rt-thead {
    box-shadow: none !important;
    color: $blue;

    .rt-th {
      border-right: none;
    }

    .rt-tr {
      .-sort-asc {
        box-shadow: none;
      }
    }
  }

  .rt-tbody {
    .rt-tr-group:first-child {
      border-top: none;
    }
  }
}

.label-type-account {
  display: inline-block;
  font-size: 14px;
  background-color: var(--primaryBgColor);
}

//styling spinners for tables
.rt-td[role="gridcell"] > .fallback-spinner {
  height: 100%;

  .loading {
    width: 20px;
    height: 20px;
    top: auto;
    left: auto;
  }
}

//adyen overwrite for mobile
.adyen-checkout__payment-method__name {
  white-space: normal;
}

//remove native arrow for input type number
input[type="number"] {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
}

@media #{$media-mobile} {
  .form-group .form-check {
    padding-left: 0;
  }
}

.label-required:after {
  content: " *";
  color: var(--error);
}

.alert-dismissible .close {
  padding: 1.25rem 1.3rem;
  text-shadow: none;

  &:hover {
    color: white;
  }
}

.alert-info {
  background: var(--secondaryBgColor);
  border-color: var(--secondaryBgColor);
  color: white;
}

.install-app {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  z-index: 1050;
  width: 100%;
  margin-bottom: 0;
  min-height: 60px;

  .alert {
    display: flex;
    width: 100%;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: var(--activePrimaryBgColor);
  color: var(--activePrimaryTextColor);
}

.badge-amount {
  background: #f2f4f7;
  color: #475467;
}

.form-group:last-of-type {
  margin-bottom: 1.5rem;
}

.card {
  min-height: 100px;
}

.pe-none {
  pointer-events: none;
}

.mh-auto {
  min-height: auto;
}

.list-circles {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  counter-reset: li;

  &__item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-bottom: 12px;

    &:last-child {
      padding-bottom: 0;
    }

    &::before {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background: var(--primaryBgColor);
      color: var(--primaryTextColor);
      counter-increment: li;
      content: counter(li);
    }
  }
}

@media (max-width: 767px) {
  .btn-xs-block {
    display: block;
    width: 100%;
  }
  /*
      Extra config:
      .btn-xs-block .btn {
          flex-grow: 1;
      }
  */
  input[type="submit"].btn-xs-block,
  input[type="reset"].btn-xs-block,
  input[type="button"].btn-xs-block {
    width: 100%;
  }
  .btn-block + .btn-xs-block,
  .btn-xs-block + .btn-block,
  .btn-xs-block + .btn-xs-block {
    margin-top: 0.5rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .btn-sm-block {
    display: block;
    width: 100%;
  }
  input[type="submit"].btn-sm-block,
  input[type="reset"].btn-sm-block,
  input[type="button"].btn-sm-block {
    width: 100%;
  }
  .btn-block + .btn-sm-block,
  .btn-sm-block + .btn-block,
  .btn-sm-block + .btn-sm-block {
    margin-top: 0.5rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .btn-md-block {
    display: block;
    width: 100%;
  }
  input[type="submit"].btn-md-block,
  input[type="reset"].btn-md-block,
  input[type="button"].btn-md-block {
    width: 100%;
  }
  .btn-block + .btn-md-block,
  .btn-md-block + .btn-block,
  .btn-md-block + .btn-md-block {
    margin-top: 0.5rem;
  }
}

@media (min-width: 1200px) {
  .btn-lg-block {
    display: block;
    width: 100%;
  }
  input[type="submit"].btn-lg-block,
  input[type="reset"].btn-lg-block,
  input[type="button"].btn-lg-block {
    width: 100%;
  }
  .btn-block + .btn-lg-block,
  .btn-lg-block + .btn-block,
  .btn-lg-block + .btn-lg-block {
    margin-top: 0.5rem;
  }
}

.col-form-label-lg,
.form-control-lg {
  font-size: 16px;
}

.badge {
  @media #{$media-mobile} {
    font-size: 1rem;
  }

  font-size: 14px;

  &.font-18 {
    font-size: 18px;
  }
}

input.form-control,
select.form-control {
  @media #{$media-mobile} {
    font-size: 1rem;
    height: 40px;
  }
}

.btn {
  @media #{$media-mobile} {
    font-size: 1rem;
  }
}

.btn-primary,
.btn-secondary {
  @media #{$media-mobile} {
    min-height: 40px;
  }
}

.flex-1 {
  flex: 1;
}

.btn-link {
  padding: 0;
  color: var(--linkPrimaryColor);

  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    box-shadow: none;
  }

  &:not(.btn-primary):not(.btn-secondary) &:hover {
    color: var(--linkPrimaryColor);
    text-decoration: underline;
  }
}

.form-inline label {
  justify-content: flex-start;
}
