.password-input-group {
  .password-input {
    position: relative;

    input::-ms-reveal,
    input::-ms-clear {
      display: none;
    }

    .password-show-hide {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0.75rem;
      bottom: 0;
      cursor: pointer;
    }
  }
}
