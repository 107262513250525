.Transactions {
  width: 100%;
  .row > div {
    display: flex;
    flex-direction: column;

    .card {
      flex: 1;
      margin-bottom: 0;

      &-body {
        border-radius: 12px;
      }
    }
  }
}
