.account-not-verified {
  .card {
    .card-header {
      padding: 0;
      background: transparent;
    }

    .content {
      max-width: 370px;
      width: 100%;
      min-height: auto;
      margin: auto;
    }
  }
}
