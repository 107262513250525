.nav-sidebar {
  .navbar {
    padding: 0.5rem 1.3rem;

    .balance-block {
      width: 100%;
      padding: 0 0 0.2rem;
      background: var(--primaryBgColor);

      .navbar-nav {
        display: flex;
        align-items: center;
      }

      a {
        color: var(--primaryTextColor);

        &:hover > span {
          border-bottom: 1px solid var(--primaryTextColor);
        }
      }

      .welcome {
        color: var(--primaryTextColor);
      }
    }
  }

  a.border-bottom-enlarge {
    position: relative;
    border-bottom: 0;
    color: $white;

    &::after {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      transition: height 0.3s, opacity 0.3s, transform 0.3s;
      transform: translateY(2px);
      background: $white;
      opacity: 0;
      content: "";
    }

    &:hover,
    &:focus {
      &::after {
        height: 1px;
        transform: translateY(0);
        opacity: 1;
      }
    }

    &.active {
      color: $white;

      &::after {
        height: 1px;
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  .nav-items-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    align-self: flex-start;

    @media #{$media-tablet} {
      margin: auto;
      justify-content: center;
    }

    & + div {
      @media #{$media-tablet} {
        margin: auto;
      }
    }

    img {
      width: 30px;
      height: 30px;
      max-width: 100%;
      max-height: 100%;
      border-radius: 50%;
    }

    .welcome {
      position: relative;
      align-self: flex-start;
    }

    .navbar-nav {
      .nav-item {
        align-self: center;

        .badge {
          padding: 0.3rem;
        }
      }
    }
  }

  .double-pipe {
    margin: 0 10px;
    color: var(--breadCrumbsPrimaryColor);
  }

  .navbar {
    display: flex;
    flex-flow: wrap row;
    justify-content: space-between;
    width: 100%;

    .logout {
      flex: 0.18;

      @media #{$media-tablet} {
        flex: 1;
        text-align: center;
        padding: 10px 0;
      }
    }
  }
}

@media #{$media-mobile} {
  .nav-sidebar {
    .navbar {
      flex-direction: row;
      justify-content: space-between;
      gap: 5px;

      .logo {
        img {
          max-height: 40px;
          width: auto;
        }
      }

      .logout {
        display: none;
      }

      .nav-toggler {
        order: 1;
        color: white;
      }

      .nav-items-wrapper {
        flex: 1;
        margin: auto;
        justify-content: flex-start;

        .welcome {
          text-align: center;

          &::after {
            content: "";
          }
        }
      }

      &-nav {
        flex-flow: row wrap;
        justify-content: center;
        min-width: 100px;
      }
    }
  }
  .mobile-nav {
    display: block;
  }
}
