.MyDetails {
  .nav-tabs {
    margin: 0;

    .nav-link {
      cursor: pointer;
      border: none;

      &.active:hover {
        color: var(--primaryTextColor);
      }
    }
  }

  .tab-content {
    .tab-pane {
      .row {
        margin: 0 -15px;
      }
    }
  }

  .card {
    margin-bottom: 1rem;

    .card-title {
      margin-bottom: 1.4rem;
    }
  }

  .col-md-12 {
    display: flex;
    flex-direction: column;
  }

  .vat-number-requested-wrapper {
    margin: 0.5rem 1.25rem 2.2rem;
  }

  .form-group {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 22px;

    & > .input-wrapper {
      flex: 0.9;
    }

    .vat-wrapper {
      flex: 0.9;

      &-item {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        div {
          &[name="vatNumber"],
          &[name="vatNumberRequested"] {
            flex: 0.46;
          }
        }
      }
    }

    .address-wrapper {
      flex: 0.9;

      &-item {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        div {
          &[name="street"],
          &[name="city"] {
            flex: 0.65;
          }

          &[name="houseNumber"],
          &[name="postalCode"] {
            flex: 0.32;
          }
        }
      }
    }

    &.address {
      .control-label {
        align-self: flex-start;
      }
    }
  }

  .access-to-account {
    .title-group {
      margin-bottom: 1.8rem;

      &.no-items {
        margin-bottom: 0;
      }
    }

    .invite-user-card {
      padding-bottom: 2rem;
      box-shadow: none;
      border: 1px solid #eaecf0;
      background: #fcfcfd;

      &__title {
        padding: 1.5rem 1.5rem 0 1.5rem;
        font-size: 16px;
      }

      .card-title {
        margin-bottom: 1.5rem;
      }

      .card-body {
        padding: 0 1.5rem;
      }

      &__form-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .form-group {
          flex-grow: 1;
          margin-bottom: 0;

          &:last-of-type {
            @media #{$media-mobile} {
              flex-grow: 1;
            }

            flex-grow: inherit;
          }
        }
      }
    }

    .inactive-users-card,
    .active-users-card {
      padding: 1.5rem;
      box-shadow: none;
      border: 1px solid #eaecf0;

      &__title {
        font-size: 16px;
      }

      &__sub-title {
        margin-bottom: 0;
        font-size: 14px;
      }

      .card-title {
        margin-bottom: 0.75rem;
      }

      .card-body {
        padding: 0;
      }

      .list {
        display: flex;
        flex-direction: column;
        padding: 0;
        list-style: none;
        margin-bottom: 0;

        &__item {
          display: flex;
          flex-wrap: wrap;
          flex: 1;
          justify-content: space-between;
          gap: 16px;
          padding: 22px 0 0;

          &:not(:last-child) {
            padding: 22px 0;
            border-bottom: 1px solid #eaecf0;
          }

          &:first-child {
            padding-top: 0;
          }

          .user {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 12px;

            .badge {
              color: white;
            }
          }

          .actions {
            @media #{$media-mobile} {
              width: 100%;
            }

            //min-width: 320px;

            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            .btn {
              flex: 1;
            }

            .delete-user-btn {
              @media #{$media-mobile} {
                width: 100%;
                min-height: 40px;
                margin-top: 0;
                max-width: 40px;
              }

              max-width: 35px;
              width: 35px;
              height: 35px;
              padding: 0.3rem;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }
}

@media #{$media-mobile} {
  .MyDetails {
    .form-group {
      flex-flow: column wrap;
      align-items: stretch;
      margin-bottom: 1.4rem;

      & > .control-label {
        width: auto;
      }

      .address-wrapper {
        .address-wrapper-item {
          .row {
            & > div {
              padding: 0;

              .input-wrapper {
                margin-bottom: 12px;
              }

              &:last-child {
                .input-wrapper {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }

      .input-wrapper {
        .row {
          & > div {
            padding: 0;
          }
        }
      }
    }
  }
}
