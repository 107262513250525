.Credit {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  .row {
    margin: 0;
    .col-lg-4 {
      display: flex;
    }
    .card {
      flex: 1;
      &:first-child {
        margin-bottom: 15px;
      }
      &:nth-child(n + 2) {
        margin: 0;
      }
      &:only-child {
        margin: 0;
      }
      &-body {
        border-radius: 4px;
      }
    }
    .balance-wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
    }
    .Charge-credit {
      .counter-input {
        position: relative;
      }
    }
    .button-group {
      display: flex;
      &.chargeable-amount {
        flex: 0.95;
        flex-wrap: wrap;
        justify-content: space-around;
        button {
          width: 30%;
          flex-wrap: wrap;
        }
      }
      .btn-block:first-child {
        margin-top: 0.5rem;
      }
    }
  }
  .invoice-data {
    display: flex;
    flex-flow: column wrap;

    .card-title {
      flex: 0.1;
    }
    .name-wrapper,
    .company-wrapper {
      flex: 0.2;
    }
    .link-wrapper {
      padding-top: 2rem;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }
    .no-invoice-data {
      margin-top: 30px;
      & + .link-wrapper {
        margin-bottom: auto;
      }
    }
    .change-vat {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding-top: 2rem;
      .fa-info::before {
        border-radius: 50%;
        background: var(--primaryBgColor);
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
      }
      .link-wrapper {
        flex: 0.3;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
      }
      &-description {
        flex: 0.95;
        display: flex;
        flex-flow: column wrap;
        p:nth-child(1) {
          color: var(--primaryBgColor);
          font-weight: 600;
        }
      }
    }
  }
  .personal-data {
    display: flex;
    align-items: flex-start;
    flex-flow: column wrap;

    .card-title {
      padding-top: 2.2rem;
      padding-bottom: 1rem;
    }

    .name-wrapper {
      flex: 0.2;
    }

    .link-wrapper {
      padding-top: 2rem;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }
  }

  .button-group {
    &.chargeable-amount {
      & > button.btn-primary {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.4rem;
      }
    }
  }
}

@media #{$media-tablet} {
  .Credit {
    .row {
      .balance-wrapper {
        flex-flow: column wrap;
      }

      .button-group {
        &.chargeable-amount {
          & > button.btn-primary {
            width: 100%;
            flex-flow: wrap;
          }
        }
      }
    }
  }
}

@media #{$media-mobile} {
  .Credit {
    .row {
      .balance-wrapper {
        flex-flow: column wrap;
      }
      .button-group {
        flex-direction: column;
        &.chargeable-amount {
          & > button {
            width: 100%;
          }
        }
      }
    }
  }
}
