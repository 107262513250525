.Charge-credit {
  .form-group:nth-child(1) {
    position: relative;
    .counter {
      @media #{$media-mobile} {
        width: 43px;
        height: 43px;
      }

      border-radius: 5px;
      background: var(--btnPrimaryBgColor);
      border: 1px solid var(--btnPrimaryBorderColor);
      width: 34px;
      height: 34px;
      color: var(--btnPrimaryColor);
      position: absolute;
      right: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:focus,
      &:active {
        outline: none;
      }
      &.increment {
        top: 10px;
      }
      &.decrement {
        bottom: 10px;
      }
    }
    .enter-amount {
      padding: 4rem 2rem;
      text-align: center;
      color: var(--primaryBgColor);
      font-weight: 700;
      font-size: 36px;
      border-radius: 4px;
      margin-bottom: 10px;
      appearance: none;
      -moz-appearance: textfield;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }
  }
  .form-check:nth-child(1) {
    align-items: stretch;
    flex-flow: column wrap;
  }
  .conditions {
    display: flex;
  }
}
