.pagination {
  display: flex;
  list-style-type: none;
  margin-top: 0.5rem;
  padding: 0;

  .pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: 0 2px;
    text-align: center;
    border-radius: 50%;

    &.dots:hover {
      border: 0;
      cursor: default;
    }

    &:hover {
      font-weight: 600;
      cursor: pointer;

      .arrow {
        font-weight: 300;
      }
    }

    &.selected {
      font-weight: 600;
    }

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border: 2px solid var(--primaryBgColor);
      border-radius: 50%;
      color: var(--primaryBgColor);
    }

    &.disabled {
      pointer-events: none;

      .arrow {
        opacity: 0.75;
      }
    }
  }
}
