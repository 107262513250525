.Cleaning-cards {
  .row {
    & > div {
      display: flex;
      flex-direction: column;
      .card {
        flex: 0 1 auto;
        margin-bottom: 0;

        @media #{$media-desktop} {
          margin-bottom: 30px;
        }

        &-body {
          border-radius: 12px;

          .react-table {
            tr td {
              @media #{$media-mobile} {
                padding: 12px 6px 12px 0;
              }

              &:last-child {
                @media #{$media-mobile} {
                  padding-right: 0;
                }
              }
            }

            .cleaning-card-label {
              @media #{$media-mobile} {
                margin-right: 0;
              }

              & + div {
                @media #{$media-mobile} {
                  margin-top: 4px;
                }
              }
            }

            .cleaning-card-description {
              @media #{$media-mobile} {
                margin-top: 4px;
              }

              &:first-child {
                @media #{$media-mobile} {
                  margin-left: 0;
                }
              }
            }

            .cleaning-card-type {
              @media #{$media-mobile} {
                margin-top: 4px;
              }

              &:first-child {
                @media #{$media-mobile} {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
      .btn-inverse,
      .btn-inverse:hover {
        background: none;
        color: $black;
        box-shadow: none;
        border: none;
      }
    }
  }
}

.drop {
  display: inline-block;
  background-size: 18px;
  background-image: url("../../assets/images/drop/drop_bg.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  width: 19px;
  height: 30px;
  text-align: center;
  margin: 0 6px;
  line-height: 30px;
  font-size: 10px;
  padding-top: 8px;
  color: #034089;

  @media #{$media-mobile} {
    margin: 0;
  }

  &:hover {
    background-image: url("../../assets/images/drop/drop_dark_bg.png");
    color: white;
  }
}
