nav.bottom-tab-navigator {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  background: white;
  border-top: 1px solid var(--gray-300);

  .nav {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .nav-item {
    flex: 1;
    a {
      color: $body-color;

      &.active {
        color: var(--primaryBgColor);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    &__icon svg {
      width: 24px;
      height: 24px;
    }

    &__label {
      font-size: 12px;
    }
  }
}
