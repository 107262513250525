.Invoice {
  .row {
    margin: 0;

    .col-md-12 {
      display: flex;
      flex-direction: column;
    }
    .card {
      flex: 0 1 auto;
      &:first-child {
        margin-bottom: 15px;
      }
      .sorts {
        display: flex;
        flex-wrap: wrap;
        .button-group {
          flex: 1;
          display: flex;
          justify-content: space-around;
          align-items: center;
          button {
            width: 23%;
          }
        }
      }
      .bg-secondary {
        padding: 3rem;
        background-color: var(--secondaryBgColor);
        .card-title,
        .card-subtitle {
          color: var(--secondaryTextColor);
        }
        .card-subtitle {
          padding-top: 100px;
        }
        input {
          border-radius: 12px;
          width: 125px;
        }
        form {
          margin-bottom: 2rem;
        }
      }
      &:nth-child(n + 2) {
        margin: 0;
      }
      &:only-child {
        margin: 0;
      }
    }
  }
  .gift-message-form {
    display: flex;
    flex-flow: row wrap;
    flex: 1;
    .Charge-credit {
      width: 50%;
    }
    &-item {
      display: flex;
      flex-flow: column wrap;
      flex: 1;
    }
  }
}

@media #{$media-mobile} {
  .Invoice {
    .row {
      .card {
        .sorts {
          flex-direction: column;
          & > .button-group {
            flex-direction: column;
            & > button {
              width: 100%;
            }
          }
        }
        .bg-secondary {
          padding: 1rem;
        }
      }
    }
  }
}
