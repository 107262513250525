.auth-form-wrapper {
  display: flex;
  flex-flow: column wrap;
  padding: 0 1.6rem 2.4rem;
  box-shadow: none;

  .logo-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    z-index: 1;
    //width: calc(50% - 3.2rem);
    margin: 0;
    padding-top: 1.6rem;
    background: white;

    &.fixed {
      position: fixed;
      top: 0;
    }

    &.relative {
      position: relative;
      width: 100%;
    }

    .img-wrapper {
      a {
        display: block;
        border: none;
      }

      img {
        max-width: 180px;
        width: 100%;
        height: 100%;
      }
    }

    .language-switcher {
      width: auto;
      border-radius: 2px;
    }
  }

  .content {
    max-width: 500px;
    width: 100%;
    margin: auto;
  }

  .header-wrapper {
    margin-bottom: 32px;
  }

  &-body {
    padding: 0;
  }

  .p-3 {
    padding-left: 0 !important;
  }

  .img-wrapper {
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .btn-primary {
    background-color: var(--btnPrimaryBgColor);
    color: var(--btnPrimaryColor);
    width: 100%;
    border-radius: 8px;
    padding: 0.65rem 0.75rem;

    &:hover {
      transform: none;
    }
  }

  .form-group {
    margin-bottom: 32px;

    label {
      display: block;
    }

    .text-danger {
      display: inline-flex;
      margin-top: 6px;
    }
  }

  .form-control {
    height: 44px;
  }

  .custom-control {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .prefill-login {
    display: block;
    width: 100%;
    margin-top: 12px;
    padding: 0.65rem 0.75rem;
  }

  .secondary-cta-text {
    margin-top: 1.5rem;
    text-align: center;
  }
}

.cover-image {
  object-fit: cover;
  height: 100vh;
  position: fixed;
}

@media #{$media-mobile-sm} {
  .card.auth-form-wrapper {
    .logo-wrapper {
      align-items: center;
      gap: 22px;

      .language-switcher {
        width: 100%;
        order: -1;
      }
    }
  }

  .cover-image {
    position: fixed;
  }
}

@media #{$media-mobile} {
  .auth {
    .row {
      margin: 0;

      div[class*="col"] {
        padding: 0;
      }
    }
  }

  .card.auth-form-wrapper {
    .logo-wrapper {
      width: 100%;
    }
  }
}

@media #{$media-tablet} {
  .auth {
    .row {
      margin: 0;

      .card.auth-form-wrapper {
        .logo-wrapper {
          width: 100%;
        }
      }
    }
  }
}
