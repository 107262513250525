.title {
  background: var(--primaryBgColor);
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 1.3rem;

  @media (min-width: 768px) {
    background: white;
  }

  &__header {
    margin-bottom: 0;
    text-transform: capitalize;
    color: var(--primaryTextColor);

    @media (min-width: 768px) {
      color: var(--breadCrumbsPrimaryColor);
    }
  }
}
