.choose-category {
  min-height: auto;
  margin-bottom: 12px;

  button.choose-category__link {
    color: $body-color;
    transition: color ease 0.8s;

    &:hover {
      color: var(--primaryBgColor);
      text-decoration: none;

      .choose-category-content {
        &__title {
          color: var(--primaryBgColor);
        }
      }
    }
  }

  a.choose-category__link {
    border-bottom: 0;
    color: $body-color;
    transition: color ease 0.8s;

    &:hover {
      color: var(--primaryBgColor);

      .choose-category-content {
        &__title {
          color: var(--primaryBgColor);
        }
      }
    }
  }

  .choose-category-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    min-height: auto;
    padding: 0;
  }

  .choose-category-content {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: 0;

    &__icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__title {
      margin-bottom: 0;
    }
  }
}
